import styled from 'styled-components';
import { HomeHeader } from '../organisms/home/HomeHeader';
import { HomeContact } from '../organisms/home/HomeContact';
import { HomeContents } from '../organisms/home/HomeContents';

const menus = [
  {
    type: "right",
    url:"/about",
  },
  {
    type: "left",
    url:"/works",
  },
]


export const Home = () => {
  return (
    <>
    {/* タイトルと紹介文 */}
     <HomeHeader></HomeHeader>
     {/* メイン */}
      <SMainContainer>
        {/* <SHeaderImg src={backgroundDesk}/> */}
        {/* 各ページへのリンクコンテナ */}
        <HomeContents menus={menus}></HomeContents>
        {/* CONTACT */}
        <HomeContact></HomeContact>
      </SMainContainer>
    </>
  );
}

// MAIN
const SMainContainer = styled.div`
`