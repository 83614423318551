import { Link } from 'react-router-dom';
import styled from 'styled-components';
import media from "styled-media-query";

export const LinkButton = (props) => {
  return (
    <>
      <SLink to={props.url}>{props.children}</SLink>
    </>
  );
}
const SLink = styled(Link)`
font-family: 'Sawarabi Mincho', serif;
  color: #660033;
  text-decoration: none;
  display:block;
  margin: 0 auto 0 auto;
  width: fit-content;
  padding: 12px 24px;
  background: #fff;
  border: 1px solid;
  border-radius: 12px;
  box-shadow: none;
  &:hover {
    cursor: pointer;
  }
  &:active {
    background: #fff;
    opacity: .6;
    color: #000;
  }
  ${media.lessThan("small")`
    margin: 144px auto 0 auto;
  `}
`