import React from 'react';
import styled from "styled-components";
import { LinkButton } from '../../atoms/button/LinkButton';

export const HomeContact = () => {

  return (
    <>
      <SContainer>
        <SContent>　お気軽にご連絡ください。</SContent>
        <LinkButton url="/contact">Contact</LinkButton>
      </SContainer>
    </>
  );
}

const SContainer = styled.div`
  width: 100%;
  height: 60vh;
  min-height: 500px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #660033;
  font-family: 'Sawarabi Mincho', serif;
`

const SContent = styled.p`
  font-family: 'Sawarabi Mincho', serif; 
  width: 480px;
  color: #660033;
  position: relative;
  z-index: 9999;
  text-align: center;
  margin: 0 0 30px 0;
`