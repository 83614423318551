import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import media from 'styled-media-query';
import { LinkButton } from '../atoms/button/LinkButton';
import thumnailNautisshippoART from './img/thumnailNautisshippoART.jpg'
import thumnailClientPichballDevils from './img/thumnailPinchballDevils.jpg'
import thumnailClientKyoheiTominaga from './img/thumnailClientKyoheiTominaga.jpg'
import thumnailClientChoujeiji from './img/Hirys_ComingSoon.jpg'


const websites = [
  {
    url: "https://nautisshippoart.com/",
    title: "ノーチのしっぽ研究所",
    background: thumnailNautisshippoART,
  },
  {
    url: "https://pinchballdevils.com/",
    title: "Pinchball Devils",
    background: thumnailClientPichballDevils,
  },
  {
    url: "https://www.kyoheitominaga.com/",
    title: "Kyohei.Tominaga",
    background: thumnailClientKyoheiTominaga,
  },
  {
    url: "#",
    title: "臨済宗妙心寺派　長慶寺",
    background: thumnailClientChoujeiji,
  },
]


export const Works = () => {

  const redirectToWebsite = (url) => {
    window.open(url, '_blank')
  };

  return (
    <>
      <SContainer>
        <STitle>Websites</STitle>
        <SWorks>
          {
            React.Children.toArray(websites.map((f, i) => {
              return (
                <>
                  <SWork src={f.background}>
                    <SWorkTitleContainer>
                      <SWorkTitle>{f.title}</SWorkTitle>
                    </SWorkTitleContainer>
                    <SImg src={f.background} />
                    <SMaskContainer>
                      <SMakingComment>
                        <LinkButton >
                          <div onClick={() => redirectToWebsite(f.url)}>View</div>
                        </LinkButton>
                      </SMakingComment>
                    </SMaskContainer>
                  </SWork >
                </>
              );
            }))
          }
        </SWorks>
      </SContainer >
    </>
  );
}

const SContainer = styled.div`
  min-height: 100vh;
  padding: 24px 0 0 0;
`
const STitle = styled.h2`
  font-family: 'Sawarabi Mincho', serif;
  text-align: center;
`
const SWorks = styled.div`
  margin: 20px 0 0 0;
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 400px));
  justify-content: center;
`
const SWork = styled.div`
  margin: 0 20px 70px;
  max-width: 400px;
  position:		relative;
`
const SWorkTitleContainer = styled.div`
  height: 48px;
`
const SWorkTitle = styled.h3`
  font-family: 'Sawarabi Mincho', serif; 
  margin: 12px 0 0 12px;
  white-space: pre-wrap;
`
const SImg = styled.img`
  width: 100%;
  transition: .2s;
  &:hover {
    cursor: pointer;
  }
`
const SMaskContainer = styled(Link)`
  width: 100%;
  aspect-ratio: 1/1;
  position: absolute;
  top: 60px;
  left: 0;
  background-color:	rgba(0,0,0,0.3);
  color: #fff;
  text-decoration: none;
  display: flex;
  align-items: center;
    

  ${media.greaterThan("large")`
    width: 100%;
    aspect-ratio: 1/1;
    position: absolute;
    top: 60px;
    left: 0;
    opacity: 0;
    background-color:	rgba(0,0,0,0.3);
    color: #fff;
    text-decoration: none;
    display: flex;
    align-items: flex-end;

    &:hover {
      opacity: .3;
    }
  `}
`
const SMakingComment = styled.div`
  width: 100%;
  height: 100%;
  line-height: 1.2;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-flow: column;
  font-family: "Yu Mincho",
`


