import React from 'react';
import styled from "styled-components";
export const HomeHeader = () => {

  return (
    <>
      <SContainer>
        <SContent>ITの推進をお手伝いしています。</SContent>
      </SContainer>
    </>
  );
}


// HEADER
const SContainer = styled.div`
  text-align: center;
  height: 60vh;
  min-height: 240px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const SContent = styled.p`
  margin: 36px auto 60px auto;
  line-height: 24px;
  width: 90%;
  max-width: 500px;
  color: #660033;
  font-family: 'Sawarabi Mincho', serif; 
`