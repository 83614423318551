import { Route, Switch } from "react-router";
import { BrowserRouter } from "react-router-dom";
import { DefaultLayout } from "../templates/DefaultLayout"
import { ScrollTop } from "./ScrollTop"
import { About } from "../pages/About";
import { Home } from "../pages/Home";
import { Works } from "../pages/Works";
import { Contact } from "../pages/Contact";

export const Router = () => {
  return(
    <BrowserRouter>
    <ScrollTop />
      <Switch>
        <Route exact path="/">
          <DefaultLayout>
            <Home></Home>
          </DefaultLayout>
        </Route>
        <Route exact path="/about">
          <DefaultLayout>
            <About></About>
          </DefaultLayout>
        </Route>
        <Route exact path="/works">
          <DefaultLayout>
            <Works></Works>
          </DefaultLayout>
        </Route>
        <Route exact path="/contact">
          <DefaultLayout>
            <Contact></Contact>
          </DefaultLayout>
        </Route>
      </Switch>
    </BrowserRouter>
  );
}
