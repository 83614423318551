import styled from "styled-components";
import media from "styled-media-query";
import { AboutCard } from "../organisms/about/AboutCard";

const step = [
  {
    title: '美術部に入部',
    startDate: '2009/7',
    content:`美術がやりたい一心で、一度入部したバドミントン部を退部し、美術部に入部。`,
  },
  {
    title: 'ベースを始める',
    startDate: '2009/8',
    content:`現在に至るまでの精力的な音楽活動の始まりのきっかけとなる。`,
  },
  {
    title: '美術部に入部／軽音楽同好会に入会',
    startDate: '2012/4',
    content:`2通りの表現方法を追求することになる。ここで高校の同級生と初めてのバンドを結成する。`,
  },
  {
    title: '千葉大学モダンジャズ研究会に入会',
    startDate: '2017/4',
    content:`プレイヤーとしての腕前を向上させるために未経験のジャンルに挑戦する。`,
  },
  {
    title: '修士号（理学）を取得',
    startDate: '2021/3',
    content:`植物生理学の分野で研究をすすめ、大学院を修了する。`,
  },
  {
    title: '中堅SIerに新卒入社',
    startDate: '2021/4',
    content:`大学院での研究の際にプログラミングを学んだことをきっかけに、IT業界へ入る。`,
  },
  {
    title: '高度情報技術者試験ITストラテジストに合格',
    startDate: '2023/6',
    content:`IT業界でのひとつの目標であった資格に合格し、目標達成を果たす。`,
  },
  {
    title: '独立',
    startDate: '2023/8',
    content:`新卒入社した会社を退社する。`,
  },
  {
    title: 'Hirysを開業',
    startDate: '2023/9',
    content:`自身のもつ技術と想いをかけあわせて、アーティストの支援を始める。`,
  },
]

export const About = () => {
  return (
    <>
      <STitle>About</STitle>
      <SContainer>
        <AboutCard step={step}></AboutCard>
      </SContainer>
    </>
  );
}

const SContainer = styled.div`
  max-width: 1170px;
  padding: 0 0 0 32px;
  margin: 32px 0 64px;
  position: relative;
  ::before {
    content: '';
    display: inline-block;
    width: 2px;
    height: 105%;
    background: #FF0000;
    position: absolute;
    left: 30px;
  }

  ${media.greaterThan("large")`
    margin: 64px auto;
    padding: 0 32px;
    ::before {
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      -webkit-transform: translate(-50%, -50%);
      -ms-transform: translate(-50%, -50%);
    }
  `}
`
const STitle = styled.h2`
  margin: 0 0 0 70px;
  font-size: 24px;
  letter-spacing: .08em;
  color: #660033;
  font-family: 'Sawarabi Mincho', serif;
  ${media.greaterThan("large")`
    width: 100%;
    margin: 0;
    text-align: center;
  `}
`